
import { Component, Vue } from 'vue-property-decorator';
import GiftshopFrontModule from '@/store/modules/GiftshopFrontModule';
import { GiftshopCode, GiftshopCodeEditModel, GiftshopFrontSection } from '@/models/Giftshop';

@Component({
    name: 'GiftshopFrontInfo',
    components: {

    },
})
export default class GiftshopFrontInfo extends Vue {
    name: string = null;
    email: string = null;
    accept: boolean = false;

    get isLoading(): boolean {
        return GiftshopFrontModule.LOADING;
    }

    get code(): GiftshopCode {
        return GiftshopFrontModule.CODE;
    }

    get nameIsValid() {
        return this.name?.length > 0 && (this.name?.trim().includes(" ") ?? false);
    }

    get inputIsValid(): boolean {
        return this.accept && this.nameIsValid;
    }

    get message(): string {
        return GiftshopFrontModule.GIFTSHOP.message ?? "";
    }

    async handleContinue() {
        GiftshopFrontModule.SET_LOADING(true);
        
        const editModel = new GiftshopCodeEditModel(this.code);
        editModel.email = this.email;
        editModel.name = this.name;

        await GiftshopFrontModule.PERSIST_CODE(editModel);

        //GiftshopFrontModule.SET_CURRENT_SECTION(this.message.length ? GiftshopFrontSection.MESSAGE : GiftshopFrontSection.PRODUCTS);
        GiftshopFrontModule.SET_CURRENT_SECTION(GiftshopFrontSection.MESSAGE);

        GiftshopFrontModule.SET_LOADING(false);
    }

    private created() {
        this.name = this.code.name;
        this.email = this.code.email;
    }
}
